import axios from "axios";
import store from "@/store";
import { getCurrentBizCode } from "@/utils/pageKeyUtil";

import { compactObjectValues } from "@/utils/ObjectUtil";
import GolfERPService from "@/service/GolfERPService";
import UnauthorizedError from "@/error/UnauthorizedError";
import { getAccessToken } from "@/utils/session/SessionUtil";
import BadRequestError from "@/error/BadRequestError";
import ForbiddenError from "@/error/ForbiddenError";
import NotFoundError from "@/error/NotFoundError";
import InternalServerError from "@/error/InternalServerError";
import { GOLF_ERP_STATUS_CODE_MESSAGES } from "@/api/v2/GolfErpAPI";

class GolfErpReportAPI {
  constructor() {
    const http = axios.create({
      baseURL: `${process.env.VUE_APP_HOST_REPORT}`,
      timeout: 30000,
    });
    http.interceptors.request.use(
      (config) => {
        const bizCode = getCurrentBizCode();
        const accessToken = getAccessToken();

        if (accessToken && !!bizCode) {
          config.headers["Authorization"] = `${bizCode} ${accessToken}`;
        }

        // Do something before request is sent
        if (!config.isBackground) {
          store._vm.$EventBus.$emit("loaderOn");
        }

        switch (config.method) {
          case "get":
            if (config.params) {
              config.params = compactObjectValues(
                JSON.parse(JSON.stringify(config.params)),
                true,
                false
              );
            }
            break;
          case "post":
          case "put":
          case "patch":
          case "delete":
            if (config.data) {
              config.data = compactObjectValues(
                JSON.parse(JSON.stringify(config.data)),
                false,
                true
              );
            }
            break;
        }

        return config;
      },
      (error) => {
        // Do something with request error
        store._vm.$EventBus.$emit("loaderOff");

        return Promise.reject(error);
      }
    );
    http.interceptors.response.use(
      (response) => {
        if (!response.config.isBackground) {
          store._vm.$EventBus.$emit("loaderOff");
        }

        const { data } = response;

        return data;
      },
      (error) => {
        if (!error.response) {
          store._vm.$EventBus.$emit("loaderOff");

          store._vm.$EventBus.$emit(
            "errToast",
            "서버로부터 응답을 받을 수 없습니다."
          );

          return Promise.reject(error);
        }

        if (!error?.config?.isBackground) {
          store._vm.$EventBus.$emit("loaderOff");
        }

        const { response } = error;
        if (!response) {
          return Promise.reject(error);
        }

        if (response.status === 401) {
          const isSessionExpired =
            error.response.data?.status === "SESSION_EXPIRED";

          if (isSessionExpired) {
            store._vm.$EventBus.$emit("sessionExpiredToast");
          }

          GolfERPService.logout({
            isSessionExpired,
          });
        } else if (response.status === 403) {
          if (error.response?.data?.status === "BLOCK_ID") {
            store._vm.$EventBus.$emit(
              "errToast",
              error.response?.data?.data ||
                "요청하신 아이디는 현재 사용중지 상태입니다.\n관리자에게 문의 바랍니다."
            );
          } else if (
            error.response?.data?.status === "BLOCK_ID_DURING_SESSION"
          ) {
            store._vm.$EventBus.$emit("alert", {
              title: "차단",
              message:
                error.response?.data?.data ||
                "요청하신 아이디는 현재 사용중지 상태입니다.\n관리자에게 문의 바랍니다.",
            });
          } else if (error.response?.data?.status === "BLOCK_PASSWORD") {
            store._vm.$EventBus.$emit(
              "errToast",
              error.response?.data?.data ||
                "요청하신 아이디는 로그인 오류 횟수를 초과하였습니다.\n관리자에게 문의 바랍니다."
            );
          } else if (
            error.response?.data?.status === "BLOCK_PASSWORD_DURING_SESSION"
          ) {
            store._vm.$EventBus.$emit("alert", {
              title: "차단",
              message:
                error.response?.data?.data ||
                "요청하신 아이디는 로그인 오류 횟수를 초과하였습니다.\n관리자에게 문의 바랍니다.",
            });
          }
        } else {
          if (GOLF_ERP_STATUS_CODE_MESSAGES[response?.data?.status]) {
            store._vm.$EventBus.$emit(
              "errToast",
              GOLF_ERP_STATUS_CODE_MESSAGES[response?.data?.status]
            );
          } else if (response?.data?.status === "CONFIRM") {
            store._vm.$EventBus.$emit("infoToast", response.data.data);
          } else if (typeof response?.data?.data === "string") {
            store._vm.$EventBus.$emit("errToast", response.data.data);
          }
        }

        switch (response.status) {
          case 400:
            throw new BadRequestError(response.data);
          case 401:
            throw new UnauthorizedError(response.data);
          case 403:
            throw new ForbiddenError(response.data);
          case 404:
            throw new NotFoundError(response.data);
          case 500:
            throw new InternalServerError(response.data);
        }

        return Promise.reject(error);
      }
    );

    this.http = http;
    this.interruptTokeSourceMap = {};
  }

  interrupt(interruptTokenId, message) {
    const cancelTokenSource = this.interruptTokeSourceMap[interruptTokenId];
    if (cancelTokenSource) {
      cancelTokenSource.cancel(message);
      delete this.interruptTokeSourceMap[interruptTokenId];
    }
  }

  // --------------------------------------------------------------------------------------
  async getReportKey(params) {
    try {
      return await this.http.post("reportKey", params, { timeout: 1000 * 60 * 10 });
    } catch (e) {
      if (e instanceof UnauthorizedError) {
        await GolfERPService.logout();
      }
      throw e;
    }
  }

  async postReportScreen(formObject, documentKey, isFileAuth = false, isPrtAuth = false, isDirectPrint = false) {
    try {
      const bizCode = getCurrentBizCode();
      const accessToken = getAccessToken();

      formObject.authorization.value = `${bizCode} ${accessToken}`;
      formObject.documentKey.value = documentKey;
      formObject.isFileAuth.value = isFileAuth.toString();
      formObject.isPrtAuth.value = isPrtAuth.toString();
      formObject.isDirectPrint.value = isDirectPrint.toString();
      formObject.submit();
    } catch (e) {
      if (e instanceof UnauthorizedError) {
        await GolfERPService.logout();
      }
      throw e;
    }
  }
}

export default new GolfErpReportAPI();
