<template>
  <div
    class="content"
    :class="[{hide: isPopup}]">
    <form
      :ref="uniqueId"
      :name="uniqueId"
      method="POST"
      :action="reportURLBase"
      :target="`${uniqueId}_Iframe`"
    >
      <input ref="documentKey" name="documentKey" />
      <input ref="authorization" name="authorization" />
      <input ref="id" name="id" :value="uniqueId" />
      <input ref="isFileAuth" name="isFileAuth"/>
      <input ref="isPrtAuth" name="isPrtAuth"/>
      <input ref="isDirectPrint" name="isDirectPrint"/>
    </form>
    <iframe
      :ref="`${uniqueId}_Iframe`"
      :name="`${uniqueId}_Iframe`"
      v-if="!isPopup"
    />
  </div>
</template>

<style scoped>
.content {height: 100%;margin: 0;border: 0;overflow-y: hidden;}
.content.hide {display: none;}
.content form input {visibility: hidden;}
.content iframe {width: 100%;height: 100%;margin-top: -21px;border: 0;}
</style>

<script>
import { v4 as createUUID } from "uuid";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import commonMixin from "@/views/layout/mixin/commonMixin";

import GolfErpReportAPI from "@/api/v2/GolfErpReportAPI";
import UnauthorizedError from "@/error/UnauthorizedError";

export default {
  props: {
    isPopup: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [confirmDialogMixin, commonMixin],
  created() {
    window.addEventListener("message", this.reportIframeEvents, false);
  },
  beforeDestroy() {
    window.removeEventListener("message", this.reportIframeEvents);
    if(this.openedWindow) {
      this.openedWindow.close();
      this.openedWindow = null;
    }
  },
  data() {
    return {
      reportURLBase: `${process.env.VUE_APP_HOST_REPORT}/report`,
      documentKeyStr: null,
      uniqueId: createUUID(),
      openedWindow: null,
    };
  },
  methods: {
    printReport() {
      this.$refs[`${this.uniqueId}_Iframe`].contentWindow.postMessage(
        "printOutsideIframe",
        "*"
      );
    },
    async postReportKey(data, type) {
      // jsonData 문자열화 작업
      let jsonData = {};
      if (data) {
        Object.keys(data).forEach((key) => {
          jsonData = Object.assign(jsonData, {
            [key]: JSON.stringify(data[key]),
          });
        });
      }

      const documentKey = await GolfErpReportAPI.getReportKey({
        type,
        ...jsonData,
      });
      return JSON.stringify(documentKey);
    },
    async postReport(data, type) {
      // 문서 생성 & report key 얻어옴
      // isFileAuth : 파일저장버튼 활성화 여부(true : 활성화, false : 비활성화)
      // isPrtAuth : 인쇄버튼 활성화 여부(true : 활성화, false : 비활성화)
      // isDirectPrint : 리포트의 뷰어를 사용하지 않고 바로 프린트 호출 여부 (isPopup = false로 해야 됨)
      const isFileAuth = !this.commonMixinIsButtonDisableByMenuAuth("FILE_AUTH");
      const isPrtAuth = !this.commonMixinIsButtonDisableByMenuAuth("PRT_AUTH");
      const isDirectPrint = data?.isDirectPrint || false;
      try {
        this.documentKeyStr = await this.postReportKey(data, type);
      } catch (e) {
        if (e.response && e.response.status === 401) {
          try {
            this.documentKeyStr = await this.postReportKey(data, type);
          } catch (ee) {
            console.error(ee);
            if (!(ee instanceof UnauthorizedError)) {
              this.errorToast(this.$t("report.popupMessage.createFail"));
            }
            return;
          }
        } else {
          console.log(e);
          this.errorToast(this.$t("report.popupMessage.createFail"));
          return;
        }
      }

      // this.documentKeyStr = "\"({'status':true,'wait':0.0,'uid':'c944d319c8caa4c68870b50db7e1806cb','version':{'build':'5.0.45','day':'20200827'}})\"";

      if (this.isPopup) {
        // ### 1. popup open
        const w = 1280; // window.outerWidth;
        const h = window.outerHeight;
        const x = window.outerWidth / 2 + window.screenX - w / 2;
        const y = window.outerHeight / 2 + window.screenY - h / 2;

        this.openedWindow = window.open(
          "",
          this.uniqueId,
          `toolbar=no,width=${w},height=${h},top=${y},left=${x}`
        );
        if (!this.openedWindow) {
          this.errorToast("팝업 차단을 해제해주십시요");
        }

        this.$refs[this.uniqueId].target = this.uniqueId;
      } else {
        // ### 2. iframe
        this.$refs[this.uniqueId].target = `${this.uniqueId}_Iframe`;
      }

      // 얻어온 문서 key로 submit
      try {
        // 얻어온 key로 submit
        await GolfErpReportAPI.postReportScreen(
          this.$refs[this.uniqueId],
          this.documentKeyStr,
          isFileAuth,
          isPrtAuth,
          isDirectPrint
        );
      } catch (e) {
        this.errorToast(this.$t("report.popupMessage.createFail"));
      }
    },
    async reportIframeEvents(e) {
      if (e.data && e.data.type) {
        switch (e.data.type) {
          case "REPORT_NOT_AUTHORIZED":
            if (e.data.id === this.uniqueId) {
              try {
                await GolfErpReportAPI.postReportScreen(
                  this.$refs[this.uniqueId],
                  this.documentKeyStr
                );
              } catch (e) {
                if (e instanceof UnauthorizedError) {
                  if (this.isPopup && this.openedWindow) {
                    this.openedWindow.close();
                  }
                } else {
                  this.errorToast(this.$t("report.popupMessage.createFail"));
                }
              }
            }
            break;
          default:
            break;
        }
      }
    },
  },
};
</script>
